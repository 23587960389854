#heavenLogo {
  color: white;
  width: 300px;

}

@font-face {
  font-family: 'Messina Sans';
  src: url('https://assets.website-files.com/61b0aaae69101e6a4a38b5c0/61b0b02956e960032d970756_MessinaSansWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('https://assets.website-files.com/61b0aaae69101e6a4a38b5c0/6200dec1e806ec4690417239_MessinaSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('https://assets.website-files.com/61b0aaae69101e6a4a38b5c0/6200df8f837ea96c17ffc2d1_MessinaSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Messinasans';
  src: url('https://assets.website-files.com/61b0aaae69101e6a4a38b5c0/62cd1a9c17822cd0508f496f_MessinaSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

h1 {
  font-family: 'Messina Sans', sans-serif;
  color: white;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: -0.04em;
}

#logo {
  fill: #ed6866;
}

.whiteText {
  color: white;
}

.textfield {
  color: white;
}